import AlertProvider from "@components/provider/AlertProvider";
import AppProvider from "@components/provider/AppProvider";
import { CacheProvider } from "@emotion/react";
import {
  browserSessionPersistence,
  getAuth,
  setPersistence,
} from "@firebase/auth";
import colorlog from "@libs/colorlog";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import "@redsift/design-system/style/redsift.css";
import type { AppProps } from "next/app";
import { Poppins, Source_Code_Pro } from "next/font/google";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { initFirebaseClientSDK } from "@libs/firebase/client-sdk-utils";
import RedSiftLoadingIndicator from "../components/RedSiftLoadingIndicator";
import GlobalAlert from "../components/common/GlobalAlert";
import * as gtag from "../libs/analytics/gtag";
import createEmotionCache from "../libs/createEmotionCache";
import theme from "../libs/theme";
import "../styles/globals.css";

const log = colorlog("pages");

// TODO: disable DEBUG level in production
// log.setLevel("DEBUG");

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

// Create a query client
const queryClient = new QueryClient();

initFirebaseClientSDK();

const sourceCodePro = Source_Code_Pro({ subsets: ["latin-ext"] });
const poppins = Poppins({ weight: "400", subsets: ["latin-ext"] });
export default function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (event: any) => {
      const path = (event || "/").split("?")[0];
      gtag.pageview({ page_path: path, page_title: path });
    };
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
    const auth = getAuth();
    setPersistence(auth, browserSessionPersistence).catch((e) => {
      log.error("_setPersistence::", e);
    });
    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("hashChangeComplete", handleRouteChange);
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <CacheProvider value={clientSideEmotionCache}>
      <Head>
        <title>Red Sift IAM</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        data-cookieconsent="statistics"
        data-cookieblock-src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        data-cookieconsent="statistics"
        type="text/plain"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <AppProvider>
            <AlertProvider>
              <main className={`${poppins.className} ${sourceCodePro.className}`}>
                <Component {...pageProps} />
                <GlobalAlert />
                <RedSiftLoadingIndicator />
              </main>
            </AlertProvider>
          </AppProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}
